// custom-bootstrap-variables.scss

// Import Bootstrap variables
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@function toRGB ($color) {
    @return red($color) + ", " + green($color) + ", " + blue($color);
}


// Map Bootstrap variables to CSS variables
:root {
    --upsio-primary-hover: #{darken($primary, 10%)};
    --light-hover: #{darken($light, 3%)};

    // Spacing
    --spacer: #{$spacer};
    --spacer-x: #{$spacer * 1.5};
    --spacer-y: #{$spacer * 1.5};

    --upsio-font-size: #{$font-size-base};
    --upsio-font-size-sm: #{$font-size-sm};

    // Other variables...
    --upsio-focus-shadow-no-color: 0 0 0 var(--upsio-focus-ring-width);
    --upsio-focus-shadow: 0 0 0 var(--upsio-focus-ring-width) var(--upsio-focus-ring-color);
    --upsio-text-muted: #{$text-muted};

    --foreground-rgb: 255, 255, 255;
    --background-color: 0, 0, 0;
    
    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;

    --text: var(--text-light);

    --ticket-background: rgba(255, 255, 255, 0.1);

    --upsio-btn-transparent-hover-bg: #{$btn-transparent-hover-bg};
    --upsio-card-bg: #{$card-bg};
    --upsio-card-hover-bg: #{$card-hover-bg};
    --upsio-message-bg: #fafafa;
    --upsio-incoming-message-bg: #e5e5ea;
    --upsio-modal-content-bg: #{$modal-content-bg};
    --upsio-modal-content-bg-rgb: #{toRGB($modal-content-bg)};
    --upsio-modal-content-box-shadow-sm-up: #{$modal-content-box-shadow-sm-up};
    --upsio-action-bg: #{$action-bg};
    --upsio-action-bg-hover: #{$action-bg-hover};
    --upsio-item-success: #{$item-success};


    --upsio-gradient-dark: linear-gradient(135deg, #7e2549, #300d1b);

    @media (prefers-color-scheme: dark) {
        --upsio-btn-transparent-hover-bg: #{$btn-transparent-hover-bg-dark};
        --upsio-card-bg: #{$card-bg-dark};
        --upsio-card-hover-bg: #{$card-hover-bg-dark};
        --upsio-text-muted: #{$text-muted-dark};
        --upsio-message-bg: #1e1e1e;
        --upsio-incoming-message-bg: #3b3b3d;
        --upsio-primary-light: #4c2936;
        --upsio-modal-content-bg: #{$modal-content-bg-dark};
        --upsio-modal-content-bg-rgb: #{toRGB($modal-content-bg-dark)};
        --upsio-modal-content-box-shadow-sm-up: #{$modal-content-box-shadow-sm-up-dark};
        --upsio-action-bg: #{$action-bg-dark};
        --upsio-action-bg-hover: #{$action-bg-hover-dark};
        --upsio-item-success: #{$item-success-dark};


    }
}