// scss-docs-start gray-color-variables
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900) !default;
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #7f31ac !default;
$pink: #c94277 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #f1c232 !default;
$green: #198754 !default;
$teal: #369ebb !default;
$cyan: #0dcaf0 !default;
$light-pink: #F8D2E1 !default;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
    "blue": $blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "light-pink": $light-pink,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800) !default;
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary: $pink !default;
$primary-light: $light-pink !default;
$secondary: $yellow !default;
$tertiary: $teal !default;
$gray: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: #F4F4F4 !default;
$dark: $gray-900 !default;
$inactive: $gray-400 !default;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
    "primary": $primary,
    "primary-light": $primary-light,
    "secondary": $secondary,
    "tertiary": $tertiary,
    "gray": $gray,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
    "inactive": $inactive) !default;
// scss-docs-end theme-colors-map

$enable-dark-mode: true !default;
$color-mode-type: 'media-query' !default;
$variable-prefix: upsio- !default;
$prefix: $variable-prefix !default;

$item-success: #deede6;
$item-success-dark: #213b2f;

$body-bg: white !default;
$body-bg-dark: #1f1e1e !default;

$body-color: rgba(0, 0, 0, 0.7) !default;
$body-color-dark: rgba(255, 255, 255, 0.8) !default;

$text-muted: rgba(0, 0, 0, 0.55) !default;
$text-muted-dark: rgba(255, 255, 255, 0.65) !default;

$card-bg: rgba(0, 0, 0, 0.04) !default;
$card-bg-dark: rgba(255, 255, 255, 0.05) !default;

$card-hover-bg: rgba(0, 0, 0, 0.08) !default;
$card-hover-bg-dark: rgba(255, 255, 255, 0.1) !default;

$border-color: rgba(0, 0, 0, 0.1) !default;
$border-color-dark: rgba(255, 255, 255, 0.125) !default;

$font-size-base: 1rem !default;
$font-size-sm: 0.75rem !default;

$modal-content-border-width: 0 !default;
$modal-content-border-radius: 2rem !default;

$modal-content-box-shadow-xs: none !default;
$modal-content-box-shadow-sm-up: 0.5rem 1rem 2rem rgba(0, 0, 0, 0.3) !default;
$modal-content-box-shadow-sm-up-dark: 0 2rem 4rem black !default;

$modal-content-bg: white !default;
$modal-content-bg-dark: #1d1d1f !default;

$modal-header-border-color: transparent !default;
$modal-header-border-width: 0 !default;
$modal-inner-padding: 1rem !default;
$modal-header-padding-y: $modal-inner-padding !default;
$modal-header-padding-x: $modal-inner-padding !default;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x 0 !default;

$modal-content-border-radius: 2rem !default;

$border-radius-sm: 0.5rem;
$border-radius: 1rem;
$border-radius-lg: 2rem;

$box-shadow: .5rem 1rem 2rem rgba(0, 0, 0, .1) !default;

$input-border-radius: $border-radius-sm;

// stylelint-disable value-keyword-case
$font-family-sans-serif: "Inter",
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans",
    "Liberation Sans",
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji" !default;
$font-family-monospace: SFMono-Regular,
    Menlo,
    Monaco,
    Consolas,
    "Liberation Mono",
    "Courier New",
    monospace !default;
// stylelint-enable value-keyword-case
$font-family-base: var(--#{$variable-prefix}font-sans-serif) !default;
$font-family-code: var(--#{$variable-prefix}font-monospace) !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$font-weight-base: $font-weight-normal !default;

$h1-font-size: $font-size-base * 2.5 !default;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

$grid-gutter-width: .5rem !default;
$container-padding-x: 2rem !default;

$gradient: linear-gradient(135deg, lighten($primary, 10%), darken($primary, 10%));

$btn-border-radius: 1.5rem !default;
$btn-padding-y: 0.5rem !default;
$btn-padding-x: 1.5rem !default;
$btn-font-size: 1.25rem !default;
$btn-line-height: 1.5rem !default;
$btn-font-weight: 500 !default;
$btn-border-width: 2px !default;

$input-padding-x: 1rem !default;
$input-padding-y: 0.75rem !default;
$input-line-height: 1.5 !default;

$btn-transparent-hover-bg: rgba(129, 129, 177, 0.1) !default;
$btn-transparent-hover-bg-dark: rgba(white, 0.2) !default;

$action-bg: rgba(0, 0, 0, 0.025) !default;
$action-bg-dark: rgba(255, 255, 255, 0.025) !default;
$action-bg-hover: rgba(0, 0, 0, 0.05) !default;
$action-bg-hover-dark: rgba(255, 255, 255, 0.05) !default;