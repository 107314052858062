@import "variables-bootstrap";
@import "~bootstrap/scss/bootstrap";
@import "./components/component-styles.scss";
@import "./pages/pages-styles.scss";

@import "variables-css";
@import "type";

@media screen {

    html,
    body,
    #root {
        width: 100%;
        max-width: 100%;
    }

    html {
        height: calc(100% + env(safe-area-inset-top));
        overflow-y: auto;
        scrollbar-gutter: stable both-edges;
        -webkit-overflow-scrolling: touch;
    }

    #root {
        main {
            padding-top: 2rem;  
            padding-bottom: 8rem;
            
        }
    }

    html:has(.message-view) {
        &,
        body,
        #root {
            overflow: hidden;
        }
        body,
        #root {
            height: 100%;
        }
        #root {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            main {
                flex-grow: 1;
            }
        }
    
    }

    body {
        #root > {
            header,
            main,
            footer {
                padding-right: 0;
                transition: padding-right 0.3s ease;

            }
        }

        &.slide-in-panel-open {
            @include media-breakpoint-down(md) {
                overflow: hidden;
            }

            @include media-breakpoint-up(xl) {
                #root > {
                    header,
                    main,
                    footer {
                        padding-right: 27rem;
                    }
                }
            }
        }
    }

    button,
    a {
        &:focus {
            outline: none;
            box-shadow: 0 0 0 var(--upsio-focus-ring-width) var(--upsio-focus-ring-color);
        }
    }
}

@media print {

    html,
    body {
        margin: 0;
        padding: 0;
    }
}

a {
    text-decoration: none;
    color: inherit;
}

.horizontal-scroll {
    padding: 0.25rem 0;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.material-symbols-rounded {
    font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
    &.small {
        font-size: 1rem;
    }
}

.add-collections-form,
.add-shelves-form,
.inline-form {
    form {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    .form-input {
        flex-grow: 1;
        margin-right: 0.5rem;

        &.mb-3 {
            margin-bottom: 0 !important;
        }
    }

    .btn {
        margin-bottom: 0.5rem;
    }
}

main.login+footer {
    display: none;
}

@media screen and (orientation: landscape) and (max-width: 1024px), screen and (max-width: 767px) {
    footer {
        display: none;
    }
}

header {
    .dropdown-menu {
        &.show {
            margin-right: 0.75rem !important;
        }
    }
}

.clipping-path {
    width: 0;
    height: 0;
    display: none;
}

.modal {
    .modal-content {
        background-color: var(--upsio-modal-content-bg);
        position: relative;
        
        @include media-breakpoint-down(md) {
            padding-top: calc(1rem + env(safe-area-inset-top));
            padding-right: calc(1rem + env(safe-area-inset-right));
            padding-bottom: calc(1rem + env(safe-area-inset-bottom));
            padding-left: calc(1rem + env(safe-area-inset-left));
        }
        
        @include media-breakpoint-up(md) {
            box-shadow: var(--upsio-modal-content-box-shadow-sm-up);
            padding-top: calc(2rem + env(safe-area-inset-top));
            padding-right: calc(2rem + env(safe-area-inset-right));
            padding-bottom: calc(2rem + env(safe-area-inset-bottom));
            padding-left: calc(2rem + env(safe-area-inset-left));
        }

        .btn-close {
            position: absolute;
            top: calc(2rem + env(safe-area-inset-top));
            right: 2rem;
        }
    }
}

.rack {
    position: relative;

    &::before,
    &::after {
        content: "";
        display: block;
        position: absolute;
        background-color: blue;
        top: 1rem;
        bottom: 1rem;

        @include media-breakpoint-down(md) {
            width: 0.25rem;
        }

        @include media-breakpoint-up(md) {
            width: 0.5rem;
        }
    }

    &::before {
        left: 0.75rem;

        @include media-breakpoint-up(md) {
            left: 0.5rem;
        }
    }

    &::after {
        right: 0.75rem;

        @include media-breakpoint-up(md) {
            right: 0.5rem;
        }
    }

    .shelf {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &:not(:last-child) {
            @include media-breakpoint-down(md) {
                border-bottom: 0.5rem solid orange;
            }

            @include media-breakpoint-up(md) {
                border-bottom: 1rem solid orange;
            }
        }

        .shelf-bay {
            flex: 1 0;
            margin: 0 0.25rem;
            position: relative;

            @include media-breakpoint-down(md) {
                margin: 0 0.125rem;
            }

            @include media-breakpoint-up(md) {
                margin: 0 0.25rem;
            }

            .crate {
                border-radius: $border-radius-sm;
                background-color: rgba(blue, 0.2);
                padding-top: 45%;

                @include media-breakpoint-down(md) {
                    margin-top: 0.25rem;
                }

                @include media-breakpoint-up(md) {
                    margin-top: 0.5rem;
                }
            }
        }
    }

    .bayNumber {
        position: absolute;
        display: block;
        background-color: var(--upsio-body-bg);
        padding: 0.25rem 0.5rem;
        border-radius: 0.25rem;
        font-size: 0.75rem;
        bottom: 0.25rem;
        left: 0.25rem;
    }
}

.add-bay-button {
    .btn {
        position: absolute;
        top: 0.75rem;
        right: 0.25rem;
        background-color: transparent;
        border: none;
        color: $body-color;

        &:hover {
            background-color: rgba(black, 0.1);
        }
    }
}

.formWrapper {
    @include media-breakpoint-up(lg) {
        background-color: var(--upsio-card-bg);
        border-radius: $border-radius-lg;
    }
}

.columns {
    &.column-list {
        columns: 2;
        column-gap: 1rem;

        @include media-breakpoint-up(xl) {
            columns: 3;
        }
    }

    .dont-wrap {
        break-inside: avoid;
    }
}

.client-icon {
    line-height: 1;

    @include media-breakpoint-down(md) {
        width: 1rem;

        .material-symbols-rounded {
            font-size: 1rem;
        }

        .badge {
            font-size: 8px;
        }
    }

    @include media-breakpoint-up(md) {
        width: 1.5rem;
    }

    &:not(:first-child) {
        margin-left: 0.25rem;
    }

    &:not(:last-child) {
        margin-right: 0.25rem;
    }
}

.validation-column {
    @include media-breakpoint-down(md) {
        margin-top: 0.25rem;
    }

    @include media-breakpoint-up(md) {
        margin-top: 0.125rem;
    }
}

div:has(> [class*="join-"]) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.join {
    &-comma,
    &-dot,
    &-with-double-slash {
        white-space: nowrap;
    }

    &-comma:not(:last-child) {
        &::after {
            content: ",\00A0";
        }
    }

    &-dot:not(:last-child) {
        &::after {
            content: "\00A0\00B7\00A0 ";
        }
    }

    &-with-double-slash:not(:last-child) {
        &::after {
            content: "\00A0//\00A0";
        }
    }
}

.icon-with-title {
    padding-left: 2rem;
    position: relative;

    .material-symbols-rounded {
        position: absolute;
        left: 0;
    }

    .icon-title {
        font-size: 1.25rem;
        font-weight: 500;
        line-height: 1.5rem;
    }
}

.no-wrap {
    white-space: nowrap;
}

.picking-block {
    background-color: var(--upsio-card-bg);
    padding: 1rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
    position: relative;

    &.item-complete {
        background-color: var(--upsio-item-success);
    }

    .picked-by {
        position: absolute;
        bottom: 2px;
        right: 16px;
        font-size: 0.5rem;
    }
}

a.bg-light {
    &:hover {
        background-color: var(--upsio-card-hover-bg) !important;
    }
}

.upsio-list-block {
    background-color: var(--upsio-card-bg);
    padding: 0.5rem 1rem;
    list-style: none;
    border-radius: 1rem;
    min-height: 56px;

    li {
        &:not(:last-child) {
            border-bottom: 1px solid $border-color;
        }
    }
}

.badge {
    .material-symbols-rounded {
        font-size: 10px;
        font-variation-settings: "FILL" 1;
        margin-right: 0.25rem;
        line-height: 10.5px;
    }

    &+.badge {
        margin-left: 0.5rem;
    }

    &.bg-inactive {
        color: $body-color;
    }
}

.badge-list {
    list-style: none;
    margin: 0 -0.25rem;
    padding: 0;

    .badge {
        margin: 0 0.25rem 0.25rem;
    }
}

.upsio-checkbox-group {
    display: flex;
    margin: 0 -0.25rem;
}

.upsio-check-button {
    position: relative;
    margin: 0 0.25rem;
    flex-grow: 1;

    &.spread-3 {
        flex: 0 0 auto;
        width: calc(33.333% - 0.5rem);
    }

    &.spread-4 {
        flex: 0 0 auto;
        width: calc(25% - 0.5rem);
    }

    @include media-breakpoint-up(lg) {
        &.spread-lg-6 {
            flex: 0 0 auto;
            width: calc(16.667% - 0.5rem);
        }
    }

    &::after {
        content: "";
        display: block;
        padding-top: 100%;
    }

    input,
    label {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        appearance: none;
        cursor: pointer;
        border-radius: 0.5rem;
    }

    input {
        z-index: 2;
        background-color: transparent;
        border: none;
        &:disabled {
            &,
            &+label {
                cursor: no-drop;
                opacity: 0.5;
            }
        }
    }

    label {
        z-index: 1;
        background-color: var(--upsio-body-bg);

        .label-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 0.75rem;
            width: max-content;
            max-width: 90%;
        }
    }

    &.check-light {
        label {
            background-color: var(--upsio-card-bg);
        }
    }

    input:hover:not(:disabled)+label {
        background-color: var(--upsio-primary-light);
    }

    input:checked+label,
    label.active {
        background-color: var(--upsio-primary);
        color: white;
    }
}

.nav-pills {
    margin: 0 -0.25rem;

    .nav-link {
        text-align: center;
        background-color: var(--upsio-card-bg);
        transition: background-color 0.5s ease;
        border-radius: 0.5rem;
        margin: 0 0.25rem;
        padding: 0.5rem 0.75rem;

        &:hover {
            background-color: var(--upsio-card-hover-bg);
        }

        .nav-number {
            font-size: 1.5rem;
            font-weight: 600;
            line-height: 1;
        }

        .nav-label {
            font-size: 10px;

            @include media-breakpoint-up(sm) {
                font-size: 0.75rem;
            }
        }
    }
}

@keyframes expandHeight {
    0% {
        max-height: 0;
        overflow: hidden;
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        max-height: 100px;
        opacity: 1;
        overflow: visible;
    }
}

.transitionHeight {
    animation: expandHeight 0.5s forwards linear;
}

.giftOptions {
    list-style: none;
    padding: 0;

    li {
        background-color: var(--upsio-card-bg);
        padding: 1rem !important;
        border-radius: 1rem;
        margin-bottom: 1rem;
        position: relative;
    }
}

.is-searching {
    overflow: hidden;
}

div:where(.swal2-container) .swal2-html-container {
    &.upsio-swal2-text {
        line-height: 1.5;
        margin-left: 2rem;
        margin-right: 2rem;
    }
}

div:where(.swal2-container) .swal2-html-container.upsio-swal2-text ul,
ul.central-list {
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
    li {
        font-size: 0.875rem;
        padding: 1rem 0;
        position: relative;
        &:not(:last-child) {
            &:after {
                content: "";
                display: block;
                border-bottom: 1px solid var(--upsio-border-color);
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 4rem;
            }
        }
    }
}

.form-switch {
    padding-left: 3rem;

    .form-check-input {
        margin-left: -3rem;
    }

    .form-check-label {
        font-size: 0.875em;
        color: var(--upsio-secondary-color);
    }
}

.info-card {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    color: var(--upsio-body-color);

    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    .info-card-content {
        flex-grow: 1;
        margin-left: 1.5rem;
    }

    .title {
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.6;
        color: var(--upsio-body-color);
        margin-bottom: 0.25rem;
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}


.btn-link {
    font-size: 1rem;
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    border: none;
    vertical-align: top;
}

* {
    scrollbar-width: thin;
    scrollbar-color: rgba($primary, 0.2) transparent;
}

*::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: $primary-light;
    border-color: var(--upsio-body-bg);
    border-width: 2px;
    border-style: solid;
    border-radius: 0.1875rem;
}

.badge {
    &.bg-info,
    &.bg-warning {
        color: #333333;
    }
}