.brandIcon {
    .iconPath {
        fill: none;
        stroke: var(--upsio-primary);
        stroke-width: 60;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-miterlimit: 10;
    }
    &.white {
        .iconPath {
            stroke: white;
        }
    }
}

.brandBox {
    max-width: 4.75rem;
    background: var(--upsio-gradient);
    line-height: 0;
    clip-path: url("#squircle");
    position: relative;
    margin-bottom: 1rem;
    &::before {
        content: "";
        display: block;
        padding-top: 100%;
    }
    @media screen and (min-width: 768px) {
        max-width: 6.25rem;
        margin-bottom: 2rem;
    }
    .brandIcon {
        display: block;
        width: 54%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.brandHorizontal {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--upsio-body-color);

    .brandBox {
        display: block;
        margin-bottom: 0;
        width: 2.5rem;
        @media screen and (min-width: 768px) {
            width: 3.75rem;
        }
    }
    .brandName {
        margin-left: 1rem;
        font-weight: 600;
        line-height: 1;
        font-size: 1.25rem;
        @media screen and (min-width: 768px) {
            font-size: 1.875rem;
        }
    }
}

.clippingPath {
    width: 0;
    height: 0;
}