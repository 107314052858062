@use '../../styles/variables-bootstrap' as variables;

.loading {
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sprite {
    z-index: 0;
    width: 200px;
    height: 200px;
    margin-bottom: 5rem;
    position: relative;
    .circle {
        display: block;
        width: 100%;
        padding: 0;
        overflow: hidden;
        &::before {
            display: block;
            content: "";
            padding-top: 100%;
        }
    }
    .circle,
    .svg,
    .iconImg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
    .circle {
        animation: rotate-circle 2.25s linear infinite;
        z-index: 2;
        @keyframes rotate-circle {
            0% {
              transform: rotateZ(0deg);
            }
            100% {
              transform: rotateZ(360deg)
            }
        }
    }
    .svgCircle {
        stroke: variables.$primary;
        stroke-width: 0.5;
        stroke-dasharray: 100;
        stroke-dashoffset: 100;
        animation: dash 2s linear infinite both;
        transform-origin: 50% 50%;
        @keyframes dash {
            0% {
                stroke-dashoffset: 100;
                transform: rotate(0);
            }
            50% {
                stroke-dashoffset: 0;
                transform: rotate(0);
            }
            100% {
                stroke-dashoffset: 100;
                transform: rotate(360deg);
            }
        }
    }
    .icon {
        z-index: 1;
        width: 60px;
        margin: 70px;
        position: relative;
    }
}