.toast {
    display: flex;
    flex-wrap: wrap;
    padding: 16px;
    background: rgba(var(--upsio-modal-content-bg-rgb), 0.84);
    backdrop-filter: blur(2.5rem);
    border: 1px solid var(--upsio-border-color);
    color: var(--normal-text);
    border-radius: 1rem;
    box-shadow: 0 4px 12px #0000001a;
    width: var(--width);
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 6px;

    &[data-x-position="right"] {
        right: 1rem;
    }

    [data-content] {
        flex: 1 1 100%;
        margin-bottom: 0.25rem;
    }
    [data-title] {
        font-size: 12px;
        font-weight: 700;
        line-height: 1rem;
        color: var(--upsio-text-muted);
    }
    [data-description] {
        font-size: 13px;
        font-weight: 400;
        line-height: 1rem;
        + [data-button] {
            margin-left: auto;
            margin-right: 0;
        }
    }
    [data-button] {
        appearance: none;
        border: none;
        cursor: pointer;
        border-radius: 4px;
        overflow: hidden;
        font-size: 12px;
        padding: 4px 8px;
        margin-inline: 0;
        line-height: 1;
        &[data-cancel] {
            background: var(--upsio-danger);
            color: white;
            & + [data-button] {
                background-color: var(--upsio-primary);
                color: white;
            }
        }
        
    }
    [data-close-button] {
        border-radius: 50px;
        background-color: var(--upsio-modal-content-bg);
        border-color: var(--upsio-border-color);
        color: var(--upsio-text-muted);
        &:hover {
            background-color: var(--upsio-modal-content-bg);
            border-color: var(--upsio-text-muted);
            color: var(--upsio-body-color);
        }
    }
    [data-content] + [data-button] {
        margin-left: auto;
    }
}