.title-wrapper {
    border-radius: 1.5rem;
    background-color: var(--upsio-card-bg);
    backdrop-filter: blur(16px);
    padding: 1rem 1.5rem;
    display: inline-block;
    @include media-breakpoint-up(md) {
        &.overlay {
            position: absolute;
        }
    }
    &.max {
        max-width: calc(100% - 100px);
    }
}

.collection-image-wrapper {
    max-width: 400px;
    width: 80%;
    margin: 0 auto 2rem;
    @media screen and (orientation: landscape) and (max-width: 1023px) {
        max-width: calc(100vh - 68px - 3rem);
    }
}

.content-with-floating-aside {
    max-width: calc(100% - 100px);
}